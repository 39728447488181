<template>
    <div>
      <TopNav />
      <div class="bottom-div">
        
        <h1 class="text-4xl">$9.99 Limited Time</h1>

        <!--<div id="countdown"></div> until the price goes back to $9.99-->

        <div class="md:hidden my-8 p-4 rounded-lg bg-blue-100 text-blue-900 shadow-lg w-full sm:w-3/6">

          <p class="text-justify">
            <ul>
              <li><b>Includes</b></li>
              <li>- Personalized Letter from Santa</li>
              <li>- Official Nice List Certificate</li>
              <li>- North Pole Bookmark</li>
              <li>- 2 Christmas Themed Stickers</li>
              <li>- Santa's Coloring Sheet</li>
              <li>- North Pole Word Search</li>
              <li>- <i>Shipping is Included</i></li>
            </ul>

            </p>
        </div>

        <div class="hidden md:block my-8 p-4 rounded-lg bg-blue-100 text-blue-900 shadow-lg w-3/6">
          <p class="text-center">
            Every letter from Santa is a treasure trove of personal touches, tailored to delight your child with a sprinkle of North Pole magic. Crafted with care, Santa's letters transform your child's achievements and wishes into a memorable Christmas keepsake. Share a detail, create a moment.
            </p>
        </div>

        <form @submit.prevent="submitOrder">
          <div class="letter-container">
            <!-- Child's First Name and Last Name -->
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Child's First Name
                </label>
                <input v-model="letter.childFirstName" :class="inputClass(letter.childFirstName)" type="text" placeholder="First Name">
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Child's Last Name
                </label>
                <input v-model="letter.childLastName" :class="inputClass(letter.childLastName)" type="text" placeholder="Last Name">
              </div>
            </div>
  
            <!-- Hobby or Interest -->
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Hobby or Interest
                </label>
                <input v-model="letter.hobby" :class="inputClass(letter.hobby)" type="text" placeholder="Hobby or Interest">
              </div>
            </div>

            <div class="mb-4">
        <label for="shipDate" class="block text-gray-700 text-sm font-bold mb-2">Ship Date:</label>
        <select v-model="letter.shipDate" id="shipDate" class="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option value="2024-12-03">December 3, 2024</option>
        </select>
      </div>

            <div class="flex flex-wrap -mx-3 mb-6 text-gray-700 text-xs pl-3">This is the address Santa will mail the letter to.</div>
  
            <!-- Street Address and Unit/Apt # -->
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-2/3 px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Street Address
                </label>
                <input v-model="letter.streetAddress" :class="inputClass(letter.streetAddress)" type="text" placeholder="Street Address">
              </div>
              <div class="w-full md:w-1/3 px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Unit / Apt #
                </label>
                <input v-model="letter.unitApt" class="appearance-none block w-full rounded py-3 px-4 leading-tight appearance-none block w-full rounded py-3 px-4 leading-tight border border-gray-200 bg-gray-200 text-gray-700" type="text" placeholder="Unit / Apt #">
              </div>
            </div>
  
            <!-- City, State, and Zip -->
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  City
                </label>
                <input v-model="letter.city" :class="inputClass(letter.city)" type="text" placeholder="City">
              </div>
              <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  State
                </label>
                <select id="stateSelect" v-model="letter.state" class="appearance-none block w-full rounded py-3 px-4 leading-tight appearance-none block w-full rounded py-3 px-4 leading-tight border border-gray-200 bg-gray-200 text-gray-700">
                    <option v-for="(name, code) in usStates" :key="code" :value="code">
                        {{ name }}
                    </option>
                </select>
                <!-- <input v-model="letter.state" :class="inputClass(letter.state)" type="text" placeholder="State"> -->
              </div>
              <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Zip
                </label>
                <input v-model="letter.zip" :class="inputClass(letter.zip)" type="text" placeholder="Zip">
              </div>
            </div>
          </div>
  
          <button type="submit" class="bg-green-500 text-white px-4 py-2 rounded shadow mt-4 ml-4">Proceed to Payment</button>
        </form>
      </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import TopNav from './TopNav.vue';

const formSubmitted = ref(false);

const letter = ref({ childFirstName: '', childLastName: '', hobby: '', streetAddress: '', unitApt: '', city: '', zip: '', state: 'AL', shipDate: '2024-12-03' });

const inputClass = (value) => {
  return ['appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white', (value || !formSubmitted.value) ? 'border border-gray-200 bg-gray-200 text-gray-700' : 'border-2 border-red-500 bg-red-100 text-red-700'].join(' ');
};

const targetTime = new Date('November 16, 2024 23:59:59').getTime();

        // Update the countdown every 1 second
        const interval = setInterval(function() {
            // Get current time
            const now = new Date().getTime();

            // Find the time difference
            const difference = targetTime - now;

            // Time calculations for days, hours, minutes and seconds
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            // Output the result in the element with id="countdown"
            document.getElementById("countdown").innerHTML = days + "d " + hours + "h "
            + minutes + "m " + seconds + "s ";

            // If the count down is over, write some text and clear interval
            if (difference < 0) {
                clearInterval(interval);
                document.getElementById("countdown").innerHTML = "EXPIRED";
            }
        }, 1000);

const usStates = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

import axios from 'axios';

// https://buy.stripe.com/dR69C91W22OX9vqfYY?client_reference_id=asdlkj1111

const submitOrder = async () => {
    formSubmitted.value = true;
    const allFieldsFilled = Object.entries(letter.value).every(([key, value]) => {
  // Skip the validation for 'unitApt'
  if (key === 'unitApt') {
    return true;
  }

  // Assuming 'state' and 'shipDate' are the keys for the select menus
  // and they should not have a default value that is an empty string.
  console.log(key, value);
  if (key === 'state' || key === 'shipDate') {
    // Check if the value is not null, undefined, or an empty string
    return value != null && value !== '';
  }

  // For other text-based inputs, trim and check for an empty string
  return value.trim() !== '';
});

  if (allFieldsFilled) {
    try {
      const response = await axios.post('https://api.northpolepostage.com/api/letters', letter.value);
      if (response.status === 201) {
        window.location.href=`https://buy.stripe.com/dR69C91W22OX9vqfYY?client_reference_id=${response.data.letter.id}`; // $9.99
        // window.location.href=`https://buy.stripe.com/3csg0xeIO4X5372fYZ?client_reference_id=${response.data.letter.id}`; // $5.00
      } else {
        console.error('Error submitting order:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting order:', error);
    }
  }
};

</script>
  
  <style>
  /* Global styles */
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
  
  .top-div {
    background-image: url('../../public/northpole.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .content {
    width: 95%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .hamburger {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    z-index: 200;
  }
  
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-100vh);
    transition: transform 0.3s ease;
    z-index: 150;
  }
  
  .navbar.active {
    transform: translateY(0);
  }
  
  .navbar a {
    color: white;
    font-weight: bold;
    font-family: Verdana, sans-serif;
    font-size: 24px;
    text-decoration: none;
    margin: 20px 0;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .navbar a:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .logo-container {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
  
  .logo {
    width: 80%;
    max-width: 400px;
    height: auto;
    margin: 0 auto;
    filter: grayscale(100%) invert(1);
  }
  
  .request-letter-btn {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 36px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
  
  .request-letter-btn:hover {
    background-color: darkred;
  }
  
  img {
    width: 100%;
  }
  
  @media screen and (max-width: 599px) {
    .request-letter-btn {
      position: fixed;
      bottom: 0;
      z-index: 150;
    }
  }
  
  .bottom-div {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card {
    width: 90%;
    max-width: 400px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card img {
    width: 100%;
    border-radius: 5px;
  }
  
  .card p {
    padding-top: 10px;
    text-align: justify;
    text-justify: inter-word;
  }
  
  @media screen and (min-width: 600px) {
    .hamburger {
      display: none;
    }
  
    .navbar {
      flex-direction: row;
      justify-content: center;
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      background-color: transparent;
      box-shadow: none;
      width: fit-content;
      height: auto;
    }
  
  
    .navbar a {
      margin: 0 20px;
    }
  
  .logo {
    width: 80%;
    max-width: 400px;
    height: auto;
    margin: 0 auto;
    filter: grayscale(100%) invert(1);
  }
  
  .top-div {
    background-image: url('../../public/northpole.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 700px;
    position: relative;
  }
  
    .content {
      flex-direction: row;
      justify-content: space-around;
      width: 90%;
    }
  
    .card {
      width: 45%;
      margin: 20px;
    }
  }

  .hidethis{
    display: none;
  }
  
  @media screen and (min-width: 1024px) {
    .content {
      width: 80%;
      flex-direction: row;
      justify-content: space-between;
    }
    
    .nonhome-logo-container .logo {
        padding: 10px 0px ;
        width: 50%; /* This will make the logo even smaller when not on home page */
        float: right;
        max-width: 350px;
    }
  
  .request-letter-btn {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 36px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 30%;
    transition: background-color 0.3s;
  }
  
  .logo-container {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 100px 0;
  }
  
    .card {
      width: 22%;
    }
  }
  </style>
  