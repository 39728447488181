import axios from "axios";

function checkForToken() {
    const userString = localStorage.getItem("user");

    if(userString) {
        axios.defaults.withCredentials = true;
        axios.interceptors.request.use(config => {
            const userObj = JSON.parse(userString);
            const token = userObj.access_token;

            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        }, error => {
            return Promise.reject(error);
        });
    }
}


let baseUrl = "http://localhost/";
let version = "api/";

if (process.env.NODE_ENV == "production") {
  baseUrl = "https://api.northpolepostage.com/";
}

export function get(route) {
    checkForToken();
  return axios
    .get(baseUrl + version + route, { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      if (error.response.status === 401) {
        // const authStore = useAuthStore();
        // authStore.logout();
      }
    });
}

export function post(route, data) {
  const request = axios
    .post(baseUrl + version + route, data, { withCredentials: true })
    .catch(function (error) {
      if (route == "register" && error.response.status === 409) {
        throw new Error(error.response.data);
      } else if (error.response.status === 401) {
        // const authStore = useAuthStore();
        // authStore.logout();
      }

      return error;
    });

  return request;
}

export function put(route, data) {
  const request = axios
    .put(baseUrl + version + route, data, { withCredentials: true })
    .catch(function (error) {
      console.log(error);
      if (error.response.status === 401) {
        // const authStore = useAuthStore();
        // authStore.logout();
      }
    });

  return request;
}

export function remove(route) {
  const request = axios
    .delete(baseUrl + version + route, { withCredentials: true })
    .catch(function (error) {
      console.log(error);
      if (error.response.status === 401) {
        // const authStore = useAuthStore();
        // authStore.logout();
      }
    });

  return request;
}

export async function csrf() {
    try {
      const response = await axios.get(baseUrl + "sanctum/csrf-cookie", {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        withCredentials: true,
      });
  
      if (response.status === 204) {
        // If the response is OK, it means the CSRF token cookie was set successfully.
        // Axios will automatically send this as the X-XSRF-TOKEN header in subsequent requests due to 'withCredentials: true'.
        return { success: true };
      } else {
        return { success: false, error: 'Failed to retrieve CSRF token.' };
      }
    } catch (error) {
      console.error("Error fetching CSRF token:", error);
      return { success: false, error: error.message };
    }
  }
  
