<template>
    <div class="flex flex-col items-center">
      <!-- Back Button -->
      <button @click="goBack" class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mb-4">
        Back
      </button>

      <button @click="viewLetter" class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mb-4">
        View Letter
      </button>
  
      <!-- Letter Details -->
      <table class="border-collapse border border-gray-300">
        <tbody>
          <tr v-for="(value, key) in letter" :key="key">
            <td class="border border-gray-300 p-2 text-right font-medium capitalize">{{ key.replace('_', ' ') }}</td>
            <td class="border border-gray-300 p-2">{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <iframe v-if="pdfUrl" :src="pdfUrl" width="100%" height="600px"></iframe>


</template>
  
<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { get } from "@/utilities/api";

const route = useRoute();
const router = useRouter();

const letter = ref({});

const pdfUrl = ref(null);

onMounted(async () => {
  try {
    const response = await get(`letters/${route.params.id}`);
    letter.value = response;
  } catch (error) {
    console.error("Error fetching letter:", error);
  }
});

const goBack = () => {
  router.back();
};

const viewLetter = () => {
  window.location.href = "/letter/" + route.params.id;
};
</script>

<style scoped>
/* Add styles as needed */
</style>
