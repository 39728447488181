<template>
    <div class="min-h-screen bg-gray-100 sm:py-12">
      <!-- Logout button at the top of the page -->
      <div class="flex justify-end px-6">
        <button @click="logout" class="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded mt-4">
          Logout
        </button>
      </div>

      <div class="relative w-4/5 mx-auto">
        <div class="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div class="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20 overflow-hidden">
          <!-- Big Number Widgets -->
          <div class="grid grid-cols-4 gap-4 mb-8">
            <div class="bg-blue-100 p-4 rounded text-center">
              <p class="text-5xl">{{ newOrders }}</p>
              <p>New Orders</p>
            </div>
            <div class="bg-blue-100 p-4 rounded text-center">
              <p class="text-5xl">{{ reviewedOrders }}</p>
              <p>Reviewed Orders</p>
            </div>
            <div class="bg-blue-100 p-4 rounded text-center">
              <p class="text-5xl">{{ printedOrders }}</p>
              <p>Printed Orders</p>
            </div>
            <div class="bg-blue-100 p-4 rounded text-center">
              <p class="text-5xl">{{ mailedOrders }}</p>
              <p>Mailed Orders</p>
            </div>
          </div>
  
          <!-- Sample Table -->
          <div class="overflow-x-auto">
            <table class="min-w-full bg-white">
              <thead>
                <tr>
                  <th class="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">Order #</th>
                  <th class="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">Child</th>
                  <th class="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">City</th>
                  <th class="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">State</th>
                  <th class="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">Status</th>
                  <th class="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">PMT ID</th>
                  <th class="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">Manage</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="letter in state.letters" :key="letter.id">
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">{{ letter.id }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">{{ letter.child_first_name }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">{{ letter.city }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">{{ letter.state }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">{{ letter.status }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">{{ letter.payment_id }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                    <button @click="manageLetter(letter.id)" class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded">
                    Manage
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { get } from "@/utilities/api";

const router = useRouter();

const state = reactive({
  letters: []
});

const fetchLetters = async () => {
  try {
    const response = await get('letters');
    state.letters = response;
  } catch (error) {
    console.error("Error fetching letters:", error);
  }
};

const newOrders = computed(() => state.letters.filter(letter => letter.status === "new").length);
const reviewedOrders = computed(() => state.letters.filter(letter => letter.status === "reviewed").length);
const printedOrders = computed(() => state.letters.filter(letter => letter.status === "printed").length);
const mailedOrders = computed(() => state.letters.filter(letter => letter.status === "mailed").length);

onMounted(() => {
  fetchLetters();
});

const logout = () => {
  router.push({ name: 'logout' });
};

const manageLetter = (letterId) => {
    console.log('manage letter', letterId);
  router.push({ name: 'letter-detail', params: { id: letterId }});
};
</script>

<style scoped>
/* You can add additional TailwindCSS styles or custom styles here */
</style>
