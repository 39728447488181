<template>
    <div v-if="!loading" class="letter-container">

        <div class="letter-content" v-html="letterText.content">
        </div>
    </div>
    <div v-if="loading">
        Loading...
    </div>
</template>

<script setup>
/* eslint-disable vue/valid-define-props */
import { ref, onMounted } from 'vue';

import { useRoute } from 'vue-router';
import { get } from "@/utilities/api";

const route = useRoute();
// const router = useRouter();

// const props = defineProps();
const letterText = ref('');
const loading = ref(true);

onMounted(async () => {
    try {
        // const response = await axios.get(`/api/letter/${route.params.id}`);
        // letterText.value = response.data.content;

        const response = await get(`letters/${route.params.id}`);
        letterText.value = response;
    } catch (error) {
        console.error("Error fetching letter:", error);
    } finally {
        loading.value = false;
    }
});
</script>

<style>
.letter-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
}

.letter-header {
    text-align: center;
    margin-bottom: 20px;
}

.letter-content {
    font-family: "Georgia", serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 2;
    text-align: justify;
}

p {
    padding:10px;
}
</style>
