import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js';

import './main.css';

// createApp(App).use(router).mount('#app');



import { createPinia } from "pinia";
import { createPersistedStatePlugin } from "pinia-plugin-persistedstate-2";
// import Toast from "vue-toastification";
// import "vue-toastification/dist/index.css";
// import App from "@/App.vue";
// import router from "@/router";
// import appRouter from "@/router/app";
// import marketingRouter from "./router/marketing";
// import "@/styles/index.css";
// import "@/styles/app.css";

const pinia = createPinia();
const installPersistedStatePlugin = createPersistedStatePlugin();
pinia.use((context) => installPersistedStatePlugin(context));

let r;



r = router;

console.log("host:", window.location.host);
const app = createApp(App);

app.directive("longpress", {
  mounted: function (el, binding, vNode) {
    // only fire on mobile
    if (window.innerWidth >= 640) {
      return;
    }

    // Make sure expression provided is a function
    if (typeof binding.value !== "function") {
      // Fetch name of component
      const compName = vNode.context.name;
      // pass warning to console
      let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`;
      if (compName) {
        warn += `Found in component '${compName}' `;
      }

      console.warn(warn);
    }

    // Define variable
    let pressTimer = null;

    // Define funtion handlers
    // Create timeout ( run function after 1s )
    let start = (e) => {
      if (e.type === "click" && e.button !== 0) {
        return;
      }

      if (pressTimer === null) {
        pressTimer = setTimeout(() => {
          // Run function
          handler();
        }, 1000);
      }
    };

    // Cancel Timeout
    let cancel = (e) => {
        e.preventDefault();
      // Check if timer has a value or not
      if (pressTimer !== null) {
        clearTimeout(pressTimer);
        pressTimer = null;
      }
    };
    // Run Function
    const handler = (e) => {
      binding.value(e);
    };

    // Add Event listeners
    el.addEventListener("mousedown", start);
    el.addEventListener("touchstart", start);
    // Cancel timeouts if this events happen
    el.addEventListener("click", cancel);
    el.addEventListener("mouseout", cancel);
    el.addEventListener("touchend", cancel);
    el.addEventListener("touchcancel", cancel);
  },
});

const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
};

app.use(r).use(pinia).use(options).mount("#app");
