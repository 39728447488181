import { createRouter, createWebHistory } from 'vue-router';

// Components that will be navigated to
import HomeComponent from '@/components/HomeComponent.vue';
import RequestLetter from '@/components/RequestLetter.vue';
import AdminPanel from '@/components/AdminPanel.vue';
import AdminLogin from '@/components/AdminLogin.vue';
import LetterDetail from '@/components/LetterDetail.vue';
import LetterComponent from '@/components/LetterComponent.vue';
import FaqComponent from '@/components/FaqComponent.vue';
import ContactComponent from '@/components/ContactComponent.vue';

console.log('in router.;')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeComponent
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FaqComponent
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactComponent
  },
  {
    path: '/request-letter',
    name: 'Request Letter',
    component: RequestLetter
  },
  {
    path: '/letter/:id',
    name: 'letter',
    component: LetterComponent,
    props: true
    },
  { 
    path: '/admin-panel', 
    component: AdminPanel,
    name: 'Admin Panel',
    meta: { requiresAuth: true }
  },
  { path: '/letters/:id', component: LetterDetail, name: 'letter-detail', props: true },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminLogin
  },
  { path: '/logout', component: null, name: 'logout' }
//   {
//     path: '/new-page-url',   // The path for the new page
//     name: 'NewPage',
//     component: NewPageComponent
//   }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),  // Using HTML5 history mode
  routes
});

  
router.beforeEach((to, from, next) => {
if (to.name === 'logout') {
    localStorage.removeItem('user'); // Remove the token from local storage
    localStorage.removeItem('auth'); // Remove the token from local storage
    next('/'); // Redirect to the login page or home page
    return;
}

if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if user is authenticated; this is a simple example.
    // You might have a more sophisticated method, like checking a Vuex store state.
    const userString = localStorage.getItem('user');

    if(!userString) {
        next('/');
        return;
    }

    // Parse the JSON string to get a JavaScript object
    const userObj = JSON.parse(userString);
    
    // Access the 'access_token' property of the object
    const accessToken = userObj.access_token;
    
    const isAuthenticated = Boolean(accessToken);
    if (!isAuthenticated) {
    next('/');
    } else {
    next();
    }
} else {
    next();
}
});

export default router;
