<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-100">
      <div class="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 class="text-2xl font-bold mb-4 text-center">Admin Login</h2>
        <form @submit.prevent="login">
          <div class="mb-4">
            <label for="email" class="block text-sm font-medium text-gray-600">Email</label>
            <input type="email" id="email" v-model="email" class="mt-1 p-2 w-full border rounded-md" required>
          </div>
          <div class="mb-4">
            <label for="password" class="block text-sm font-medium text-gray-600">Password</label>
            <input type="password" id="password" v-model="password" class="mt-1 p-2 w-full border rounded-md" required>
          </div>
          <button type="submit" class="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700">Login</button>
        </form>
      </div>
    </div>
  </template>
  
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { csrf, post } from "@/utilities/api";

const email = ref('');
const password = ref('');
const router = useRouter();


const login = async () => {
  try {

    await csrf();

    const user = await post('login', {
      email: email.value,
      password: password.value
    });

    if (!user) {
      console.log('oh no user found!', user);
      return false;
    }else {
      localStorage.setItem("user", JSON.stringify(user.data));
      router.push('/admin-panel');
    }
    
  } catch (error) {
    console.error("Error logging in:", error);
  }
};
</script>
  
  <style scoped>
  /* You can add additional TailwindCSS styles or custom styles here */
  </style>
  