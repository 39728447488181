<template>
    <!-- Conditionally set class based on the current route -->
    <div :class="isHomePage ? 'top-div' : 'non-home-top-div'">
        <div class="content">
          <button @click="toggleMenu" class="hamburger">&#9776;</button>

          <nav :class="{ active: menuActive }" class="navbar">
            <a href="/">Home</a>
            <a href="/request-letter">Letters</a>
            <a href="/faq">FAQ</a>
            <a href="/contact">Contact</a>
          </nav>
        </div>
        <div :class="isHomePage ? 'logo-container' : 'hidethis'">
            <img src="../../public/logo.png" alt="Logo" class="logo">
          <!-- Conditionally show the button based on the current route -->
          <button v-if="!isRequestLetterRoute" @click="navigateToNewPage" class="request-letter-btn">Order A Letter</button>
        </div>
      </div>
</template>
    
<script setup>
    import { ref, computed } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    
    const menuActive = ref(false);
    const router = useRouter();
    const route = useRoute();
    
    // Computed property to check if the current route is /request-letter
    const isRequestLetterRoute = computed(() => route.path === '/request-letter');

    // Computed property to check if the current route is home
    const isHomePage = computed(() => (route.path === '/' || route.path === ''));
    
    const toggleMenu = () => {
      menuActive.value = !menuActive.value;
    };
    
    const navigateToNewPage = () => {
      router.push('/request-letter'); // Replace with your desired route
    };
</script>
